import React from 'react'
import { graphql } from 'gatsby'
import { Layout, HTMLRenderer, PageWrapper } from '@retina-packages/retina-theme-bootstrap'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import  MultiColVideoContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MultiColVideoContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { patientCS } from './constants';
import { deriveCommunityResourceVideo, reusableDataBuilders } from '@retina-packages/retina-theme-bootstrap/packages/helpers';
import { CommunityResources, FooterImageGroup, GenTestBenefitWrapperMain, RepWrapper } from './styles';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { EsHeaderContainer, HomePageFooterContainer } from '../../../css/common/style';

const CommunutyResourceTemplate = (props: Props) => {
  /**
   * Global section data building
   */
  const pgProp: PageQueryResult = props.data
  const blocksCO = pgProp.nodePage.relationships.field_paragraphs_gcso
  const validCOBlocks = blocksCO.filter((x: any) => 'relationships' in x)

  const pgContextsCS = props.pageContext
  const htmlStaticFilesCS = {
    nonSVGImages: pgContextsCS.nonSVGImages,
    svgMediaImages: pgContextsCS.svgMediaImages,
    allMediaDocument: pgContextsCS.documents
  }
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientCS.mainMenu, siteFooterMenu: patientCS.patFooterMenu });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientCS.footer
  const exitPopupTitle = patientCS.exitPopup
  const hcpSwitcherTitle = patientCS.hcpSwitcher
  const topNavTitle = patientCS.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks: validCOBlocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  const siteLogos = deriveMultiLogo({blocks: blocksCO, title: patientCS.siteLogo})
  const bannerHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.banner})

  /**
   * Page specific data building
   */
  const anchroLinkHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.anchroLink})
  const allisonVideoHead = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.allisonVideoHead})
  const communitySupData = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.crTitleCard})
  const logoGardenHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.logoGarden})
  const videoTopParaHTML = fullHTMLDataBuilder({blocks: blocksCO, title: patientCS.videoTopPara})
  const videoSection = validCOBlocks.filter((crco: any) => crco.relationships.field_reusable_paragraph.label === patientCS.videoCR).map(
    (crcoi: any) => deriveCommunityResourceVideo(crcoi))?.at(0)

  return (
    <EsHeaderContainer>
      <GenTestBenefitWrapperMain>
      <HomePageFooterContainer>
        <CommunityResources className='community-resources'>
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            staticMediaFiles={htmlStaticFilesCS}
            siteLogos={siteLogos}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            backToTopContent={retinaConfig.backToTopContent}
            gtmBackToTopLabel={patientCS.backToTopGTM}
            hcpValidate={retinaConfig.hcpValidate}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={patientCS.footerClassName}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
          >
            <PageWrapper className={`pagewrapper community-resources`}>
              <MobileBanner className="mobile-banner clinical-trials-banner">
                <HTMLRenderer data={htmlStaticFilesCS} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <HTMLRenderer data={htmlStaticFilesCS} html={anchroLinkHTML} />
              <RepWrapper className='o-container'>
                <HTMLRenderer data={htmlStaticFilesCS} html={videoTopParaHTML} />
              </RepWrapper>
              <MultiColVideoContainer
                accountId={retinaConfig.accountId}
                communityResourcesVideoSection={videoSection}
                viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                { ...patientCS.video1Attrs }
              />
              <HTMLRenderer data={htmlStaticFilesCS} html={communitySupData} />
              <FooterImageGroup className='logo-section o-column--full o-bottom-space '>
                <HTMLRenderer data={htmlStaticFilesCS} html={logoGardenHTML} tagName='div' className='o-container' />
              </FooterImageGroup>
              <HTMLRenderer data={htmlStaticFilesCS} html={allisonVideoHead} />
            </PageWrapper>
          </Layout>
        </CommunityResources>
        </HomePageFooterContainer>
      </GenTestBenefitWrapperMain>
    </EsHeaderContainer>
  )
}

export default CommunutyResourceTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... CommunityResourcePageQuery
      }
    }
  }
`
