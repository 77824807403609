export const patientCS: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  banner: "Banner - HTML - CR - DTC - Retina Spain",
  footer: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu Consumer - Retina Spain",
  videoTopPara: "Video Heading - CR - DTC - Retina Spain",
  videoCR: "Video Section - CR - DTC - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  crTitleCard: "Title Card - CR - DTC - Retina Spain",
  logoGarden: "Logo Garden HTML - CR - DTC - Retina Spain",
  anchroLink: "Discover - CR - DTC - Retina spain",
  backToTopGTM: "back to top - community resources",
  video1Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Genes 101"
      },
      {
        "data-gtm-event-label": "inheritance 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Herencia 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Genes 101"
      },
      {
        "data-gtm-event-label": "inheritance 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Ver la transcripción para Herencia 101"
      }
    ]
  },
  footerClassName: "community-resources-footer"
}

